@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Public Sans", sans-serif;
}

// Buttons
.btn,
.btn-sm,
.btn-xs {
  @apply font-medium text-sm inline-flex items-center justify-center border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out;
}

.btn {
  @apply px-2.5 py-2;
}

.btn-sm {
  @apply px-2 py-1;
}

.btn-xs {
  @apply px-2 py-0.5;
}

.overflow-x-auto::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.overflow-x-auto::-webkit-scrollbar {
  height: 8px; /* height of horizontal scrollbar ← You're missing this */
  width: 6px;
  background-color: #f5f5f5;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background-color: #4db752;
  border-radius: 25px;
}

.pagination {
  margin: 15px auto;
  display: flex;
  justify-content: center;
  list-style: none;
  outline: none;
  margin-bottom: 2rem;
}
.pagination > .active > a {
  background-color: #4db752;
  border-color: #4db752;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #4db752;
  padding: 5px 10px;

  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #4db752;
  border-color: #4db752;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #4db752;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.pagination .previous.disabled,
.pagination .next.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.pagination .previous,
.pagination .next {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .previous a,
.pagination .next a {
  border: none;
}

.pagination li a {
  border-radius: 6px;
  margin-right: 0.5rem;
}

.hide-popup {
  display: none;
}

@media screen and (max-width: 370) {
  .hide-icon {
    display: none;
  }
}
